import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">Lot 55, Jalan Peramah Permai Bersatu, Seksyen 16, 88263, Shah Alam Selangor</p>
        <p className="p__cormorant" style={{color: '#DCCA87', margin: '2rem 0'}}>Opening Hours</p>
        <p className="p__opensans" >Mon - Friday: 10:00 am - 10:00 pm</p>
        <p className="p__opensans" >Sat - Sunday: 10:00 am - 2:00 am</p>
      </div>

      <button className="custom__button" style={{marginTop: '2rem'}}>Visit Us</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="findus" />
    </div>
    
  </div>
);

export default FindUs;
